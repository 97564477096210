import { NgTemplateOutlet } from '@angular/common'
import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core'
import { MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog'

@Component({
  selector: 'sb-dialog',
  template: `
    <ng-template #defaultTitleTemplate>{{ title }}</ng-template>
    @if (title || titleTemplateRef) {
      <div mat-dialog-title>
        <ng-container [ngTemplateOutlet]="titleTemplateRef || defaultTitleTemplate" />
      </div>
    }
    <mat-dialog-content>
      <ng-container [ngTemplateOutlet]="contentTemplateRef" />
    </mat-dialog-content>
    @if (actionsTemplateRef) {
      <mat-dialog-actions align="end">
        <ng-container [ngTemplateOutlet]="actionsTemplateRef" />
      </mat-dialog-actions>
    }
  `,
  styles: [':host { max-width: 100%; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatDialogTitle, NgTemplateOutlet, MatDialogContent, MatDialogActions],
})
export class DialogComponent {
  @ContentChild('actions') actionsTemplateRef?: TemplateRef<unknown>
  @ContentChild('content') contentTemplateRef: TemplateRef<unknown>
  @Input() title?: string
  @ContentChild('title') titleTemplateRef: TemplateRef<unknown>
}
