import { Injectable, Injector, inject, signal } from '@angular/core'
import { DateAdapter } from '@angular/material/core'
import { TranslocoService } from '@ngneat/transloco'
import { Store } from '@ngrx/store'
import { PaymentMethod } from '@stripe/stripe-js'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/it'
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs'
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators'
import { DateRange } from 'src/app/common/components/filters/date-range/date-range.model'

import { Coupon } from '~core/models/coupon.model'
import { DataStore } from '~core/models/data-store.model'
import { DateFilter } from '~core/models/date-filter.model'
import { FacebookAdAccount } from '~core/models/facebook-ad-account.model'
import { FacebookPage } from '~core/models/facebook-page.model'
import { InstagramAccount } from '~core/models/instagram-account.model'
import { Helper } from '~core/services/helper.service'
import { RollbarService } from '~core/services/rollbar.service'
import { environment } from '~env'
import { FacebookAccount } from '~features/integrations/models/facebook-account.model'
import { LinkedinAccount } from '~features/integrations/models/linkedin-account.model'
import { StoreLinkedinOrganizationRequest } from '~features/integrations/models/linkedin-organization.model'
import * as IntegrationActions from '~features/integrations/store/actions'
import { selectFacebookAccountsCount, selectLinkedinAccountsCount } from '~features/integrations/store/selectors'
import { LinkedinOrganization } from '~features/linkedin-organization/models/linkedin-organization.model'
import { Channel } from '~features/projects/models/channel.model'
import { Project } from '~features/projects/models/project.model'
import { UpgradeDialogComponent } from '~features/subscription/components/upgrade-dialog/upgrade-dialog.component'
import { User } from '~features/user/models/user.model'

import { ApiService } from './api.service'
import { JwtService } from './jwt.service'
import { DialogService } from './ui/dialog.service'

// defineLocale('it', itLocale)

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _currentChannel = new BehaviorSubject<Channel | LinkedinOrganization>({} as Channel | LinkedinOrganization)
  // eslint-disable-next-line @typescript-eslint/member-ordering
  currentChannel = this._currentChannel.asObservable().pipe(distinctUntilChanged())
  private _currentDateFilter = new BehaviorSubject<DateFilter>({} as DateFilter)
  // eslint-disable-next-line @typescript-eslint/member-ordering
  currentDateFilter = this._currentDateFilter.asObservable().pipe(distinctUntilChanged())
  private _currentProject = new BehaviorSubject<Project>({} as Project)
  // eslint-disable-next-line @typescript-eslint/member-ordering
  currentProject$ = this._currentProject.asObservable().pipe(distinctUntilChanged())
  // eslint-disable-next-line @typescript-eslint/member-ordering
  currentProjectId$ = this.currentProject$.pipe(map(({ _id }) => _id))
  private _dataStore: DataStore
  private _isAuthenticated = new ReplaySubject<boolean>(1)
  // eslint-disable-next-line @typescript-eslint/member-ordering
  isAuthenticated = this._isAuthenticated.asObservable()
  private _user = new BehaviorSubject<User>({} as User)
  // eslint-disable-next-line @typescript-eslint/member-ordering
  user = this._user.asObservable().pipe(
    distinctUntilChanged(),
    filter((u) => !!u._id),
  )
  startupCompleted = signal(false)
  #dialogService = inject(DialogService)
  #translateService = inject(TranslocoService)

  constructor(
    private store: Store,
    private helper: Helper,
    private injector: Injector,
    private apiService: ApiService,
    private jwtService: JwtService,
    private dateAdapter: DateAdapter<any>,
  ) {
    this._dataStore = {} as DataStore
  }

  get currentUser(): User {
    return this._user.getValue()
  }

  addPaymentMethod(payment_method_id: string | PaymentMethod): Observable<User> {
    return this.apiService.post(`/v1.0/users/${this._dataStore.user._id}/payment-methods`, { payment_method_id }).pipe(
      map((user) => {
        this._dataStore.user = new User(user)
        this._user.next({ ...this._dataStore }.user)
        return user
      }),
    )
  }

  attemptAuth(credentials: { email: string; password: string }): Observable<User> {
    return this.apiService.post('/v1.0/auth/login', credentials).pipe(
      switchMap((response) => {
        // Save JWT sent from server in localstorage
        this.jwtService.saveToken(response.access_token)
        // retrieve the authenticated user
        return this.apiService.get('/v1.0/auth/me')
      }),
      map((user) => {
        this.setAuth(user)
        return user
      }),
    )
  }

  canAddFacebookAccount(): Observable<boolean> {
    const plan = this.currentUser.plan
    return this.store.select(selectFacebookAccountsCount).pipe(
      take(1),
      map((count) => plan.max_integrations_per_type > count || plan.max_integrations_per_type === -1),
    )
  }

  canAddLinkedinAccount(): Observable<boolean> {
    const plan = this.currentUser.plan
    return this.store.select(selectLinkedinAccountsCount).pipe(
      take(1),
      map((count) => plan.max_integrations_per_type > count || plan.max_integrations_per_type === -1),
    )
  }

  canCreateKpi(): Observable<boolean> {
    return this.apiService.get('/v1.0/can-create-kpi').pipe(map((response) => response.canCreate))
  }

  canCreateReport(): Observable<boolean> {
    return this.apiService.get(`/v1.0/projects/${this._currentProject.value._id}/can-create-report`)
  }

  canCreateSegment(): Observable<boolean> {
    return this.apiService
      .get(`/v1.0/users/${this._dataStore.user._id}/can-create-segment`)
      .pipe(map((response) => response.canCreate))
  }

  cancelSubscription(): Observable<User> {
    return this.apiService.delete(`/v1.0/users/${this._dataStore.user._id}/cancel-subscription`).pipe(
      tap((user) => {
        this._dataStore.user = new User(user)
        this._user.next({ ...this._dataStore }.user)
      }),
    )
  }

  changePlan(plan_id: string, coupon = null): Observable<{ user: User }> {
    return this.apiService.put(`/v1.0/users/${this._dataStore.user._id}/change-plan`, { plan_id, coupon }).pipe(
      tap((response) => {
        this._dataStore.user = new User(response.user)
        this._user.next({ ...this._dataStore }.user)
      }),
    )
  }

  create(user): Observable<User> {
    user.preferences = {
      timezone: this.helper.guessTimezone(),
      locale: this.helper.getPreferredLang(),
    }

    return this.apiService
      .post('/v1.0/auth/register', user)
      .pipe(switchMap(() => this.attemptAuth({ email: user.email, password: user.password })))
  }

  createSetupIntent() {
    return this.apiService.get('/v1.0/stripe/create-setup-intent').pipe(map((setupIntent) => setupIntent.client_secret))
  }

  delete(): Observable<any> {
    return this.apiService.delete(`/v1.0/users/${this._dataStore.user._id}`)
  }

  destroyChannel(channel: Channel) {
    let path
    switch (channel.type) {
      case 'facebook': // todo change in facebook-page
        path = 'channels/facebook-pages'
        break
      case 'instagram': // todo change in instagram-account
        path = 'channels/instagram-accounts'
        break
      case 'facebook-ad-account':
        path = 'channels/facebook-ad-accounts'
        break
      case 'linkedin-organization':
        path = 'channels/linkedin-organizations'
        break
    }
    const url = `/v1.1/projects/${this._dataStore.project._id}/${path}/${channel._id}`
    return this.apiService.delete(url).pipe(tap(() => this.onDestroyChannel(channel)))
  }

  destroyProject(project: Project) {
    return this.apiService.delete(`/v1.1/projects/${project._id}`).pipe(tap(() => this.onDestroyProject(project)))
  }

  downgradeSubscription(payload) {
    return this.apiService.post(`/v1.0/users/${this._dataStore.user._id}/downgrade-subscription`, { ...payload }).pipe(
      tap((user) => {
        this._dataStore.user = new User(user)
        this._user.next({ ...this._dataStore }.user)
      }),
    )
  }

  facebookLogin(code: string, facebookRedirectUri: string): Observable<any> {
    return this.apiService
      .post('/v1.0/auth/facebook-login', {
        code,
        facebookRedirectUri,
      })
      .pipe(
        switchMap((response) => {
          // Save JWT sent from server in localstorage
          this.jwtService.saveToken(response.access_token)
          // retrieve the authenticated user
          return this.apiService.get('/v1.0/auth/me')
        }),
        map((user) => {
          this.setAuth(user)
          return user
        }),
      )
  }

  facebookRegister(code: string, facebookRedirectUri: string): Observable<any> {
    const timezone = this.helper.guessTimezone()
    const locale = this.helper.getPreferredLang()
    return this.apiService
      .post('/v1.0/auth/facebook-register', {
        code,
        facebookRedirectUri,
        timezone,
        locale,
      })
      .pipe(
        switchMap((response) => {
          // Save JWT sent from server in localstorage
          this.jwtService.saveToken(response.access_token)
          // retrieve the authenticated user
          return this.apiService.get('/v1.0/auth/me')
        }),
        map((user) => {
          this.setAuth(user)
          return user
        }),
      )
  }

  // Verify JWT in localstorage with server & load user's info.

  // This runs once on application startup.
  getChannelUrl(channel: Channel) {
    if (!channel || !channel.type) {
      return '/'
    }
    const project = this._dataStore.user.getProjectByChannelId(channel._id)
    switch (channel.type) {
      case 'facebook':
        return `/projects/${project._id}/facebook-page/${channel._id}`
      case 'instagram':
        return `/projects/${project._id}/instagram-account/${channel._id}`
      default:
        return `/projects/${project._id}/${channel.type}/${channel._id}`
    }
  }

  getCouponData(coupon: string): Observable<Coupon> {
    return this.apiService.get(`/v1.0/stripe/coupon/${coupon}`).pipe(map((response) => response.data))
  }

  getCurrentChannel(): Channel {
    return this._dataStore.channel as Channel
  }

  getCurrentDateFilter() {
    return this._currentDateFilter.getValue()
  }

  getCurrentProject(): Project {
    return this._currentProject.getValue()
  }

  getCurrentUser() {
    return this._user.getValue()
  }

  hasAllCapabilities(capabilities: string[]) {
    // If the user is an admin then have access to all features
    if (this._dataStore.user.plan?.capabilities.includes('sudo')) {
      return true
    }
    let hasCapabilities = true
    capabilities.forEach((capability) => {
      if (!this._dataStore.user.plan?.capabilities.includes(capability)) {
        hasCapabilities = false
      }
    })
    return hasCapabilities
  }

  hasAnyCapability(capabilities: string[]) {
    // If the user is an admin then have access to all features
    if (this._dataStore.user.plan.capabilities.includes('sudo')) {
      return true
    }

    let hasCapabilities = false
    capabilities.forEach((capability) => {
      const hasCapability = this._dataStore.user.plan.capabilities.includes(capability)
      if (hasCapability) {
        hasCapabilities = hasCapabilities || hasCapability
      }
    })
    return hasCapabilities
  }

  hasCapability(capabilities: string[], all = true): Observable<boolean> {
    let hasCapabilities: boolean
    return this.isAuthenticated.pipe(
      filter((isAuth) => isAuth),
      switchMap(() => {
        if (all) {
          hasCapabilities = this.hasAllCapabilities(capabilities)
        } else {
          hasCapabilities = this.hasAnyCapability(capabilities)
        }
        return of(hasCapabilities)
      }),
    )
  }

  invoices() {
    return this.apiService
      .get(`/v1.0/users/${this._dataStore.user._id}/invoices`)
      .pipe(map((response) => response.data))
  }

  isDowngrading(): Observable<boolean> {
    return this.isAuthenticated.pipe(
      filter((isAuth) => isAuth),
      switchMap(() => of(!!this._dataStore.user.downgrading_from && !this._dataStore.user.onboarding.firstTime)),
    )
  }

  lastStoredUser(): Observable<User> {
    return this.isAuthenticated.pipe(
      filter((isAuth) => isAuth),
      switchMap(() => this.user),
    )
  }

  onRefreshChannel(channel: Channel) {
    const project = this._dataStore.user.getProjectByChannelId(channel._id)

    const projectIndex = this._dataStore.user.getProjectIndexById(project._id)
    this._dataStore.user.projects[projectIndex].channels = project.channels.map((c) =>
      c._id === channel._id ? channel : c,
    )

    this._user.next(new User(this._dataStore.user))
    this._currentProject.next(new Project(this._dataStore.project))
    if (this.getCurrentChannel()) {
      this.setChannel(this.getCurrentChannel()._id)
    }
  }

  onboardingDone(type: 'segments' | 'firstTime' | 'facebookAds') {
    return this.apiService
      .put(`/v1.0/users/${this._dataStore.user._id}/onboarding-done`, {
        which: type,
      })
      .pipe(
        tap((user) => {
          this._dataStore.user = new User(user)
          this._user.next({ ...this._dataStore }.user)
        }),
      )
  }

  populate() {
    // If JWT detected, attempt to get & store user's info
    if (this.jwtService.getToken()) {
      this.apiService.get('/v1.0/auth/me').subscribe({
        next: (user) => {
          this.setAuth(user)
          this.startupCompleted.set(true)
        },
        error: () => this.purgeAuth(),
      })
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth()
      this.startupCompleted.set(true)
    }
  }

  purgeAuth() {
    // Remove JWT from localstorage
    this.jwtService.destroyToken()
    // Set auth status to false
    this._isAuthenticated.next(false)
  }

  redeemCoupon(coupon_id: string) {
    return this.apiService.patch(`/v1.1/users/${this.currentUser._id}/redeem-coupon`, { coupon_id })
  }

  refresh(): Observable<any> {
    return this.apiService.post('/v1.0/auth/refresh').pipe(
      take(1),
      tap((response) => {
        // Save JWT sent from server in localstorage
        this.jwtService.saveToken(response.access_token)
      }),
    )
  }

  refreshFacebookAccountChannels(facebookAccount: FacebookAccount): void {
    facebookAccount.facebook_ad_accounts.forEach((c: Channel) => this.onRefreshChannel({ ...c }))
    facebookAccount.facebook_pages.forEach((c: Channel) => this.onRefreshChannel({ ...c }))
    facebookAccount.instagram_accounts.forEach((c: Channel) => this.onRefreshChannel({ ...c }))
  }

  refreshLinkedinAccountChannels(linkedinAccount: LinkedinAccount): void {
    linkedinAccount.organizations.forEach((c: Channel) => this.onRefreshChannel({ ...c }))
  }

  resumeSubscription() {
    return this.apiService.put(`/v1.0/users/${this._dataStore.user._id}/resume-subscription`, {}).pipe(
      tap((user) => {
        this._dataStore.user = new User(user)
        this._user.next({ ...this._dataStore }.user)
      }),
    )
  }

  saveOnboardingSurvey(answers: any) {
    const url = `/v1.0/users/${this._dataStore.user._id}/onboarding-survey`
    return this.apiService.put(url, answers)
  }

  setChannel(id: string): void {
    this._dataStore.channel = this._dataStore.project?.getChannelById(id)
    this._currentChannel.next({ ...this._dataStore.channel })
  }

  setDate(date: dayjs.Dayjs) {
    const previousDate = this._dataStore.dateFilter.date

    if (!date.isSame(previousDate)) {
      this._dataStore.dateFilter.date = date
      this._currentDateFilter.next({ ...this._dataStore.dateFilter })
    }
  }

  setProject(id: string) {
    this._dataStore.project = this._dataStore.user.getProjectById(id)
    this._currentProject.next(new Project(this._dataStore.project))
  }

  setRange(since: dayjs.Dayjs, until: dayjs.Dayjs) {
    const previousSince = this._dataStore.dateFilter.range.since
    const previousUntil = this._dataStore.dateFilter.range.until

    if (!since.isSame(previousSince) || !until.isSame(previousUntil)) {
      this._dataStore.dateFilter.range = new DateRange(since, until)
      this._currentDateFilter.next({ ...this._dataStore.dateFilter })
    }
  }

  setRollbarPeopleTracking(user) {
    this.injector.get(RollbarService).configure({
      payload: {
        person: {
          id: user._id, // required
        },
      },
    })
  }

  startSubscription(plan_id, coupon = null) {
    return this.apiService.post(`/v1.0/users/${this._dataStore.user._id}/start-subscription`, { plan_id, coupon }).pipe(
      tap((response) => {
        this._dataStore.user = new User(response.user)
        this._user.next({ ...this._dataStore }.user)
      }),
    )
  }

  storeFacebookAdAccount(
    facebookAdAccount: Channel | FacebookAdAccount,
    projectId: string = this._dataStore.project._id,
  ) {
    const url = `/v1.1/projects/${projectId}/channels/facebook-ad-accounts`
    return this.apiService
      .post(url, facebookAdAccount)
      .pipe(tap((facebookAdAccount: Channel) => this.onAddChannel(facebookAdAccount)))
  }

  storeFacebookPage(
    facebookPage: Channel | FacebookPage,
    projectId: string = this._dataStore.project._id,
  ): Observable<Channel> {
    const url = `/v1.1/projects/${projectId}/channels/facebook-pages`
    return this.apiService.post(url, facebookPage).pipe(tap((facebookPage: Channel) => this.onAddChannel(facebookPage)))
  }

  storeInstagramAccount(
    instagramAccount: Channel | InstagramAccount,
    projectId: string = this._dataStore.project._id,
  ): Observable<Channel> {
    const url = `/v1.1/projects/${projectId}/channels/instagram-accounts`
    return this.apiService
      .post(url, instagramAccount)
      .pipe(tap((instagramAccount: Channel) => this.onAddChannel(instagramAccount)))
  }

  storeLinkedinOrganization(
    linkedinOrganization: StoreLinkedinOrganizationRequest,
    projectId: string = this._dataStore.project._id,
  ): Observable<Channel> {
    const url = `/v1.1/projects/${projectId}/channels/linkedin-organizations`
    return this.apiService.post(url, linkedinOrganization).pipe(
      tap(({ data }) => this.onAddChannel(data)),
      map(({ data }) => data),
    )
  }

  storeProject(project: Project): Observable<Project> {
    return this.apiService
      .post('/v1.1/projects', project)
      .pipe(tap((project: Project) => this.onAddProject(new Project(project))))
  }

  update(user): Observable<User> {
    return this.apiService.put('/user', { user }).pipe(
      map((data) => {
        // Update the currentUser observable
        this._user.next(data.user)
        return data.user
      }),
    )
  }

  updateEmail(newEmail: string, currentPassword: string): Observable<any> {
    return this.apiService
      .put(`/v1.0/users/${this._dataStore.user._id}/email`, {
        email: newEmail,
        passwordConfirm: currentPassword,
      })
      .pipe(
        tap(() => {
          this._dataStore.user.email = newEmail
          this._user.next(new User(this._dataStore.user))
        }),
      )
  }

  updateEmailNotifications(newsletter: boolean, celebrations: boolean): Observable<any> {
    return this.apiService
      .put(`/v1.0/users/${this._dataStore.user._id}/email-notifications`, {
        newsletter,
        celebrations,
      })
      .pipe(
        tap(() => {
          this._dataStore.user.emailNotifications = {
            newsletter,
            celebrations,
          }
          this._user.next(new User(this._dataStore.user))
        }),
      )
  }

  updatePassword(newPassword: string, currentPassword: string): Observable<any> {
    return this.apiService.put(`/v1.0/users/${this._dataStore.user._id}/password`, {
      password: newPassword,
      passwordConfirm: currentPassword,
    })
  }

  updatePreferences(time_format_24: string, week_start_day: number, timezone: string, locale: string): Observable<any> {
    return this.apiService
      .put(`/v1.0/users/${this._dataStore.user._id}/preferences`, {
        timeFormat24: time_format_24 === 'true',
        weekStartDay: week_start_day,
        timezone: timezone,
        locale: locale,
      })
      .pipe(
        tap(() => {
          this._dataStore.user.preferences = {
            timeFormat24: time_format_24 === 'true',
            weekStartDay: week_start_day,
            timezone: timezone,
            locale: locale,
          }
          // Set the user language
          this.setLocale(locale)
          this._user.next(new User(this._dataStore.user))
        }),
      )
  }

  updateProject(project: Project) {
    return this.apiService
      .put(`/v1.1/projects/${project._id}`, project)
      .pipe(tap((project: Project) => this.onUpdateProject(new Project(project))))
  }

  /**
   * Open the upgrade dialog
   * @param translationKey -> must point to an object including Title and Content keys
   * @returns MatDialogRef
   */
  openUpgradeDialog(translationKey: string) {
    return this.#dialogService.open(UpgradeDialogComponent, {
      data: {
        title: this.#translateService.translate(`${translationKey}.Title`),
        content: this.#translateService.translate(`${translationKey}.Content`),
      },
      width: '835px',
    })
  }

  private onAddChannel(channel: Channel) {
    const selectedProject = this._dataStore.project
    selectedProject.channels.push(channel)
    if (channel.useAsProjectPicture) {
      const projectIndex = this._dataStore.user.getProjectIndexById(selectedProject._id)
      this._dataStore.user.projects[projectIndex].picture = channel.picture
      this._dataStore.project = this._dataStore.user.projects[projectIndex]
    }
    this.setDefaultDateFilter()

    this._user.next(new User(this._dataStore.user))
    this._currentProject.next(new Project(this._dataStore.project))
    this.store.dispatch(IntegrationActions.loadIntegrations())
  }

  private onAddProject(project: Project) {
    this._dataStore.user.projects.push(project)
    this.setProject(project._id)
    this._user.next(new User(this._dataStore.user))
  }

  private onDestroyChannel(channel: Channel) {
    const updatedChannels = this._dataStore.project.channels.filter((c) => c._id !== channel._id)
    const projectIndex = this._dataStore.user.getProjectIndexById(this._dataStore.project._id)

    this._dataStore.user.projects[projectIndex].channels = updatedChannels
    this._user.next(new User(this._dataStore.user))
    this._currentProject.next(new Project(this._dataStore.project))
  }

  private onDestroyProject(project: Project) {
    this._dataStore.user.projects = this._dataStore.user.projects.filter((p) => p._id !== project._id)
    this._user.next(new User(this._dataStore.user))
  }

  private onUpdateProject(project: Project) {
    const updatedProjects = []
    this._dataStore.user.projects.forEach((projectItem) => {
      if (projectItem._id === project._id) {
        projectItem = project
      }
      updatedProjects.push(projectItem)
    })

    this._dataStore.user.projects = updatedProjects
    this._user.next(new User(this._dataStore.user))
  }

  private setAuth(user: User): void {
    this._dataStore.user = new User(user)

    if (environment.production) {
      this.setRollbarPeopleTracking(this._dataStore.user)
      this.setCustomerlyData(this._dataStore.user)
    }

    this.setDefaultDateFilter()

    this.store.dispatch(IntegrationActions.loadIntegrations())

    // Set current user data into observable
    this._user.next(this._dataStore.user)
    // Set isAuthenticated to true
    this._isAuthenticated.next(!this.jwtService.isTokenExpired())
    // Set the user language
    this.setLocale(this._dataStore.user.preferences.locale)
  }

  private setCustomerlyData(user) {
    // Check if customerly exists
    if (typeof (<any>window).customerly !== 'undefined') {
      ;(<any>window).customerly.update({
        app_id: '4edc732d',
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        user_id: user._id,
      })
    }
  }

  private setDefaultDateFilter() {
    // Set date range values
    const today = dayjs().endOf('day')
    const aWeekAgo = today.clone().subtract(27, 'days').startOf('day')
    this._dataStore.dateFilter = {
      range: new DateRange(aWeekAgo, today),
      date: today,
    }

    this._currentDateFilter.next({ ...this._dataStore.dateFilter })
  }

  private setLocale(locale) {
    this.#translateService.setActiveLang(locale)
    dayjs.locale(locale)
    this.dateAdapter.setLocale(locale)
  }
}
