import { NgClass, NgStyle } from '@angular/common'
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core'

export type AvatarSize = 'xxs' | 'xs' | 'sm' | 'lg' | 'xl' | 'xxl'

@Component({
  selector: 'sb-avatar',
  template: `
    <div
      class="avatar"
      [ngClass]="{
        'avatar-xxs': size === 'xxs',
        'avatar-xs': size === 'xs',
        'avatar-sm': size === 'sm',
        'avatar-lg': size === 'lg',
        'avatar-xl': size === 'xl',
        'avatar-xxl': size === 'xxl'
      }"
    >
      @if (!imageSrcError) {
        <img (error)="onError()" [alt]="title" class="avatar-img" [src]="src" />
      }
      @if (imageSrcError) {
        <div class="rounded-circle flex">
          @if (initials) {
            <svg width="100%" height="100%" viewBox="0 0 100 100">
              <circle cx="50%" cy="50%" r="50" fill="#b7d0d6" />
              <text
                x="50"
                y="73"
                text-anchor="middle"
                textLength="75%"
                fill="#06667c"
                [ngStyle]="{ fontSize: '60px', fontWeight: 600, fontFamily: 'monospace' }"
              >
                {{ initials }}
              </text>
            </svg>
          }
        </div>
        @if (!initials) {
          <img class="avatar-img rounded-circle" [src]="missingPic" />
        }
      }
      <ng-content></ng-content>
    </div>
  `,
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgStyle],
})
export class AvatarComponent {
  imageSrcError = false
  initials: string
  missingPic = '/assets/img/avatars/social-placeholder.jpg'
  @Input() size: AvatarSize = null
  @Input() title: string
  private _src: string

  @HostBinding('class') get classes() {
    return this.size ? `avatar-${this.size}` : null
  }

  @Input()
  set src(value: string) {
    this.imageSrcError = false
    this._src = value
  }

  get src(): string {
    return this._src
  }

  onError() {
    this.calculateInitials()
    this.imageSrcError = true
  }

  private calculateInitials() {
    const parts = this.title
      .replace(/[^a-z0-9\s]/gi, '')
      .replace(/\s\s+/g, ' ')
      .split(' ')
      .slice(0, 2)
    this.initials = parts.map((part) => part.substring(0, 1).toUpperCase()).join('')
  }
}
