import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { TranslocoModule } from '@ngneat/transloco'
import { Observable } from 'rxjs'

import { ButtonComponent } from '~common/ui/button/button.component'
import { DialogComponent } from '~common/ui/dialog/dialog.component'
import { TextComponent } from '~common/ui/text/text.component'
import { UserService } from '~core/services'
import { User } from '~features/user/models/user.model'

@Component({
  selector: 'sb-upgrade-dialog',
  template: `
    <sb-dialog [title]="data.title">
      <ng-template #content>
        <div class="flex flex-wrap items-center pb-4">
          <div class="my-4 w-full md:hidden">
            <img class="img-fluid" src="/assets/img/upgrade.png" />
          </div>
          <div class="w-full md:w-1/2">
            <sb-text>
              <span [innerHTML]="data.content"></span>
            </sb-text>
            <sb-button (click)="onUpgrade()" fill>
              {{ 'words.upgrade' | transloco }}
            </sb-button>
            <sb-button class="mt-4 w-full" variant="outlined" fill>
              <a href="https://sbam.io/it/contattaci" target="_blank">
                {{ 'words.contactUs' | transloco }}
              </a>
            </sb-button>
          </div>
          <div class="hidden md:block md:w-1/2">
            <img class="img-fluid" src="/assets/img/upgrade.png" />
          </div>
        </div>
      </ng-template>
    </sb-dialog>
  `,
  standalone: true,
  imports: [DialogComponent, TextComponent, ButtonComponent, TranslocoModule],
})
export class UpgradeDialogComponent implements OnInit {
  user: Observable<User>

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<UpgradeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; content: string },
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.user = this.userService.user
  }

  onUpgrade() {
    this.router.navigate(['/user/subscription/update/']).then(() => this.dialogRef.close())
  }
}
