import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion'
import { NgClass, NgTemplateOutlet } from '@angular/common'
import { Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation, input } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { ThemePalette } from '@angular/material/core'
import { MatIconModule } from '@angular/material/icon'
import { TooltipPosition, MatTooltipModule } from '@angular/material/tooltip'

import { IconFill, Icon } from '~icons'

import { BoxComponent } from '../box/box.component'

export declare type ButtonVariant =
  | 'filled'
  | 'outlined'
  | 'text'
  | 'elevated'
  | 'icon'
  | 'fab'
  | 'miniFab'
  | 'tonal'
  | 'square'
  | 'menu'

export type ButtonSize = 'sm' | 'md'

export type ButtonType = 'button' | 'submit' | 'reset' | 'cancel'

@Component({
  selector: 'sb-button',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatButtonModule, NgClass, NgTemplateOutlet, MatTooltipModule, BoxComponent, MatIconModule],
  template: `
    @if (variant === 'elevated') {
      <button
        [type]="type"
        [ngClass]="{ 'w-full': fill, icon: icon, 'icon-end': iconEnd, sm: size() === 'sm' }"
        mat-raised-button
        [color]="themeColor"
        [disabled]="disabled()"
        (click)="onClick($event)"
      >
        <ng-container [ngTemplateOutlet]="content" />
      </button>
    }

    @if (variant === 'text') {
      <button
        [type]="type"
        mat-button
        [color]="themeColor"
        [disabled]="disabled()"
        (click)="onClick($event)"
        [ngClass]="{ 'w-full': fill, icon: icon, 'icon-end': iconEnd, sm: size() === 'sm' }"
      >
        <ng-container [ngTemplateOutlet]="content" />
      </button>
    }

    @if (variant === 'filled') {
      <button
        [type]="type"
        mat-flat-button
        [color]="themeColor"
        [disabled]="disabled()"
        (click)="onClick($event)"
        [ngClass]="{ 'w-full': fill, icon: icon, 'icon-end': iconEnd, sm: size() === 'sm' }"
      >
        <ng-container [ngTemplateOutlet]="content" />
      </button>
    }

    @if (variant === 'outlined') {
      <button
        [type]="type"
        [ngClass]="{ 'w-full': fill, icon: icon, 'icon-end': iconEnd, sm: size() === 'sm' }"
        mat-stroked-button
        [color]="themeColor"
        [disabled]="disabled()"
        (click)="onClick($event)"
      >
        <ng-container [ngTemplateOutlet]="content" />
      </button>
    }

    @if (variant === 'tonal') {
      <button
        [type]="type"
        [ngClass]="{ 'w-full': fill, icon: icon, 'icon-end': iconEnd, sm: size() === 'sm' }"
        mat-flat-button
        [color]="themeColor"
        [disabled]="disabled()"
        class="tonal-button"
        (click)="onClick($event)"
      >
        <ng-container [ngTemplateOutlet]="content" />
      </button>
    }

    @if (variant === 'square') {
      <button
        [type]="type"
        [ngClass]="{ 'w-full': fill, icon: icon, 'icon-end': iconEnd }"
        mat-stroked-button
        [disabled]="disabled()"
        class="square-button"
        (click)="onClick($event)"
      >
        <ng-container [ngTemplateOutlet]="content" />
      </button>
    }

    @if (variant === 'menu') {
      <button
        [type]="type"
        mat-stroked-button
        [disabled]="disabled()"
        class="menu-button"
        (click)="onClick($event)"
        [ngClass]="{ 'w-full': fill, icon: icon, 'icon-end': iconEnd }"
      >
        <ng-container [ngTemplateOutlet]="content" />
      </button>
    }

    @if (variant === 'icon') {
      <button
        [type]="type"
        mat-icon-button
        [color]="themeColor"
        [disabled]="disabled()"
        (click)="onClick($event)"
        [matTooltip]="tooltip"
        [matTooltipPosition]="tooltipPosition"
      >
        <ng-container [ngTemplateOutlet]="content" />
      </button>
    }

    @if (variant === 'fab') {
      <button [type]="type" mat-fab [color]="themeColor" [disabled]="disabled()" (click)="onClick($event)">
        <ng-container [ngTemplateOutlet]="content" />
      </button>
    }

    @if (variant === 'miniFab') {
      <button [type]="type" mat-mini-fab [color]="themeColor" [disabled]="disabled()" (click)="onClick($event)">
        <ng-container [ngTemplateOutlet]="content" />
      </button>
    }

    <ng-template #content>
      <sb-box direction="row" items="center">
        @if (icon && !iconEnd) {
          <mat-icon [svgIcon]="icon" />
        }
        @if (!['fab', 'miniFab', 'icon'].includes(variant.toString())) {
          <ng-content />
        }
        @if (variant === 'menu') {
          <mat-icon [svgIcon]="IconsFill.ArrowDropDown" class="menu-arrow" />
        }
        @if (icon && iconEnd) {
          <mat-icon [svgIcon]="icon" class="icon-end" />
        }
      </sb-box>
    </ng-template>
  `,
  styles: `
    @use 'variables' as var;
    @use 'mixins';

    @mixin iconPadding($x) {
      &.icon {
        padding-left: $x - 8px;

        &.icon-end {
          padding-left: $x;
          padding-right: $x - 8px;
        }
      }
    }

    sb-button {
      max-width: 100%;

      a {
        text-decoration: none;
        color: inherit;
      }

      button.mdc-button {
        --mdc-filled-button-container-shape: #{var.$button-radius};
        --mdc-protected-button-container-shape: #{var.$button-radius};
        --mdc-unelevated-button-container-shape: #{var.$button-radius};
        --mdc-outlined-button-container-shape: #{var.$button-radius};
        --mdc-text-button-container-shape: #{var.$button-radius};
        overflow: hidden;
        height: auto !important;
        padding: var.$button-padding-y var.$button-padding-square-x;
        line-height: var.$button-line-height;
        transition: all 200ms cubic-bezier(0.2, 0, 0, 1);

        @include iconPadding(var.$button-padding-x);

        &.sm:not(.square-button) {
          padding: var.$button-padding-y-sm var.$button-padding-x-sm;
          font-size: var.$button-font-size-sm;
          line-height: var.$button-line-height-sm;
          @include iconPadding(var.$button-padding-x-sm);
        }

        .mat-mdc-button-touch-target {
          height: 100% !important;
        }

        .mdc-button__label {
          max-width: 100%;
          @include mixins.truncate-line();
        }

        mat-icon.mat-icon {
          flex-shrink: 0;
          height: var.$button-icon-size;
          width: var.$button-icon-size;
          margin-right: var.$button-icon-margin;

          &.icon-end {
            margin-right: 0;
            margin-left: var.$button-icon-margin;
          }
        }

        &.square-button {
          --mdc-outlined-button-container-shape: #{var.$button-radius-square};
          padding: var.$button-padding-square-y var.$button-padding-square-x;

          @include iconPadding(var.$button-padding-square-x);
        }

        &.menu-button {
          --mdc-outlined-button-container-shape: #{var.$button-radius-menu};
          padding: var.$button-padding-menu;

          mat-icon.menu-arrow {
            height: 24px;
            width: 24px;
            margin-right: 0;
            flex-shrink: 0;
          }
        }

        &.mdc-button--unelevated:hover {
          box-shadow: var(--mdc-protected-button-container-elevation);
        }
      }

      button.mat-mdc-fab {
        --mdc-fab-container-shape: #{var.$fab-radius};
      }

      button.mat-mdc-mini-fab {
        --mdc-fab-container-shape: #{var.$fab-mini-radius};
      }

      &.opened {
        button.mdc-button.menu-button mat-icon.menu-arrow {
          transform: rotate(180deg);
        }
      }
    }
  `,
})
export class ButtonComponent {
  disabled = input(false, { transform: coerceBooleanProperty })
  @Input() icon: IconFill | Icon | null = null
  @Output() sbClick: EventEmitter<Event> = new EventEmitter<Event>()
  @Input() themeColor: ThemePalette = 'primary'
  @Input() type: ButtonType = 'button'
  @Input() variant: ButtonVariant = 'filled'
  @Input() tooltip: string
  @Input() tooltipPosition: TooltipPosition = 'right'
  @HostBinding('style.display') display: string = 'inline-flex'
  size = input<ButtonSize>('md')
  protected readonly IconsFill = IconFill

  private _fill: boolean = false

  @Input()
  get fill() {
    return this._fill
  }

  set fill(value: BooleanInput) {
    this._fill = coerceBooleanProperty(value)
    this.display = this._fill ? 'flex' : 'inline-flex'
  }

  private _iconEnd: boolean = false

  @Input()
  get iconEnd() {
    return this._iconEnd
  }

  set iconEnd(value: BooleanInput) {
    this._iconEnd = coerceBooleanProperty(value)
  }

  onClick($event: Event): void {
    this.sbClick.emit($event)
  }
}
